


















import { Component, Emit, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component
export default class RefreshChats extends Vue {
  @Prop({ type: Boolean, required: true }) haveChats!: boolean

  @Emit()
  chatsUpdating(isLoading: boolean) {
    return isLoading
  }

  async updateChatsInfo() {
    this.chatsUpdating(true)

    await this.$store.dispatch('requestChatsInfo')

    this.chatsUpdating(false)
  }
}
